/* Bootstrap libraries required for variables and mixins used in below sheets */

.hidden {
  display: none !important;
}

.invisible {
  opacity: 0 !important;
}

.invisible-children > * {
  opacity: 0 !important;
}

.collapsed {
  flex: 0 0 0 !important;
  transition: flex 400ms ease-in-out;
}

.no-overflow {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.w-100 {
  width: 100%;
}

.p-1 {
  padding: 1rem;
}

.px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.p-2 {
  padding: 2rem;
}

.px-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pr-1 {
  padding-right: 1rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pl-2 {
  padding-left: 2rem;
}

.pr-2 {
  padding-right: 2rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pr-4 {
  padding-right: 4rem;
}

.smaller-font {
  font-size: 1.5rem;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.error {
  background-color: #f0c8d0;
  color: #BA324F;
}

.text-center {
  text-align: center;
}

.abs-fill-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.fill {
  position: relative;
  height: 100%;
  width: 100%;
}

.flex-auto {
  flex: 0 0 auto;
}

.flex-none {
  flex: 0;
}

.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-item {
  position: relative;
  flex: 1;
  transition: flex 400ms ease-in-out;
}

.flex-item-2 {
  position: relative;
  flex: 2;
  transition: flex 400ms ease-in-out;
}

.flex-item-3 {
  position: relative;
  flex: 3;
  transition: flex 400ms ease-in-out;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-align-center {
  align-items: center;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-wrap {
  flex-wrap: wrap;
}

.red,
.alert-danger {
  color: #BA324F;
}

.alert-info {
  color: #4A90E2;
  border-color: rgba(74, 144, 226, 0.5);
}

.color-neutral {
  color: #A4A4A4;
}

.color-info {
  color: #4A90E2;
}

.green,
.success {
  color: #83CE96;
}

.cursor-pointer {
  cursor: pointer;
}

#create-report {
  flex: 1;
  background-color: white;
}

#create-report > section.section:not(:last-child) {
  border-bottom: 1px solid #D8D8D8;
}

#create-report .date-separator {
  height: 3.4rem;
  line-height: 3.4rem;
  padding: 0 0.5rem;
}

#create-report > section.create-report-notifications {
  border: none;
  flex-direction: column;
}

#create-report > section.create-report-notifications .alert {
  flex: 1;
}

#create-report .lookup-filters {
  display: flex;
  flex-wrap: wrap;
}

.create-report-filter {
  /*
    Used to prevent filters on last row from expanding to width.
    If the remaining space is less than 25%, the :after will wrap
    causing the last row of filters to distribute across the full
    width of the container
  */
}

.create-report-filter .flex-wrap:after {
  content: "";
  flex: 1 0 auto;
  min-width: 25%;
}

.filter-dropdown:not(:last-child) {
  margin-right: 2rem;
}

.filter-option .primary {
  font-weight: bold;
  padding-right: 0.5rem;
}

.filter-option .context {
  color: #A4A4A4;
}

.filter-option .context .context-label {
  color: #A4A4A4;
  text-transform: uppercase;
  font-weight: 600;
  font-size: .8em;
}

.filter-option .context .context-label:after {
  content: ":";
  padding-right: 0.5rem;
}

.filter-option .context:not(:last-child):after {
  content: "/";
  padding: 0 .5rem;
  font-weight: normal;
}

.create-report-daterange .form-control {
  min-height: 4rem;
}

.create-report-breakout,
.create-report-daterange {
  flex: 1 1 40%;
  max-width: 40%;
}

.create-report-submit .btn:disabled {
  opacity: 1;
}

.create-report-submit .fa-spin {
  animation-duration: 1s;
}

.checkbox.sub30 {
  font-size: 95%;
  color: #4B5055;
  padding-left: 1rem;
  padding-bottom: 1rem;
}

.checkbox.sub30.disabled {
  color: #A4A4A4;
}

.create-report-clear.btn-disabled {
  cursor: not-allowed;
  color: #A4A4A4;
}

.create-report-clear.btn-disabled:hover {
  color: #A4A4A4;
}

.create-report-clear.btn-disabled:active {
  box-shadow: none;
}

