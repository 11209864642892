.hidden {
  display: none !important;
}

.invisible {
  opacity: 0 !important;
}

.invisible-children {
  & > * {
    opacity: 0 !important;
  }
}

.collapsed {
  flex: 0 0 0 !important;
  transition: flex 400ms ease-in-out;
}

.no-overflow {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.w-100 {
  width: 100%;
}

.p-1 {
  padding: 1rem;
}

.px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.p-2 {
  padding: 2rem;
}

.px-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pr-1 {
  padding-right: 1rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pl-2 {
  padding-left: 2rem;
}

.pr-2 {
  padding-right: 2rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pr-4 {
  padding-right: 4rem;
}

.smaller-font {
  font-size: 1.5rem;
}

.ellipsis {
  @include ellipsis;
}

.error {
  background-color: $ltRed;
  color: $red;
}

.text-center {
  text-align: center;
}

.abs-fill-wrapper {
  @include abs-fill;
}

.fill {
  @include fill;
}

.flex-auto {
  @include flex-auto;
}

.flex-none {
  flex: 0;
}

.flex-row {
  display: flex;
}

.flex-column {
  @include flex-column;
}

.flex-item {
  position: relative;
  @include flex-item;
}

.flex-item-2 {
  position: relative;
  @include flex-item-unit(2);
}

.flex-item-3 {
  position: relative;
  @include flex-item-unit(3);
}

.flex-center {
  @include flex-center;
}

.flex-align-center {
  align-items: center;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-wrap {
  flex-wrap: wrap;
}

.red,
.alert-danger {
  color: $red;
}

.alert-info {
  color: $info;
  border-color: rgba($info, .5);
}

.color-neutral {
  color: $mdGrey;
}

.color-info {
  color: $info;
}

.green,
.success {
  color: $green;
}

.cursor-pointer {
  cursor: pointer;
}
