@import "./../../stylesheets/variables";

.report-history {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;

  & > section.section:not(:last-child) {
    border-bottom: 1px solid $ltGrey;
  }

  .file-download {
    display: flex;
  }
}

.report-history-pivot {
  content: "";
  clear: both;
  margin: 0 5rem;
  border-top: 1px dashed $ltGrey;
  padding: 1rem 0;

  .column {
    float: left;
    flex: 1;
    padding-right: 2rem;
    min-width: 180px;
    max-width: 180px;
  }

  .filter-name {
    margin: 1%;
    font-weight: bold;
  }

  .filter-data {
    margin: 1%;
    text-transform: capitalize;
  }
}

.ReactTable {
  border: none;

  .rt-table {
    overflow: hidden;
    min-height: 20rem;
  }

  .rt-td,
  .rt-thead .rt-th {
    padding: {
      left: 2rem;
      right: 2rem;
    }
  }

  .rt-thead .rt-th {
    border-color: $ltGrey;
  }

  .rt-resizable-header-content {
    text-align: left;

    &:after {
      font-family: "Font Awesome 5 Pro";
      font-size: 1.5rem;
      padding-left: .5rem;
      float: right;
    }
  }

  .rt-thead .rt-th.-sort-asc,
  .rt-thead .rt-td.-sort-asc {
    .rt-resizable-header-content:after {
      content: "\f0d8";
    }
  }

  .rt-thead .rt-th.-sort-desc,
  .rt-thead .rt-td.-sort-desc {
    .rt-resizable-header-content:after {
      content: "\f0d7";
    }
  }

  .rt-thead .rt-th.-sort-asc,
  .rt-thead .rt-th.-sort-desc,
  .rt-thead .rt-td.-sort-asc,
  .rt-thead .rt-td.-sort-desc {
    box-shadow: none;
    color: white;
    background-color: $blue;
  }

  .fas, .far {
    font-size: 2rem;
    padding: 0 1rem;
  }

  .rt-tr,
  .rt-tr-group {
    padding: .5rem;
  }

  .rt-thead {
    font-weight: 800;
    text-transform: uppercase;
    color: $dkGrey;

    &.-header {
      box-shadow: initial;
      border-bottom: 1px solid $dkGrey;
    }
  }

  &.-scrollable {
    .rt-thead.-header {
      padding-right: 17px;
    }
  }

  .rt-tbody .rt-tr-group {
    border-bottom: 2px solid rgba($ltGrey, .5);
  }
}
