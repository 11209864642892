html, body {
  width: 100%;
  height: 100%;
  display: flex;
}

#app {
  @include flex-item;
  display: flex;
  height: 100%;
  max-width: 100%;

  .main-content header {
    padding-bottom: 2rem;
  }

  .app-wrapper {
    @include flex-column;
    @include flex-item;
    max-width: 100%;
  }

  .main-content {
    @include flex-column;
    position: relative;
    overflow: auto;
    padding: 4rem 8rem;
    @include flex-item;
    overflow: visible;

    & > .disabled {
      filter: grayscale(100%) blur(.5rem);
    }
  }

  .section {
    display: flex;
    padding: 2rem 0;
  }

  .section > .section {
    padding: 1rem;
  }

  .invalid-input {
    border: 1px solid rgba(red, .5);
    border-radius: $border-radius-base;
  }

  .section > .section:not(:last-child) {
    padding-right: 2rem;
  }
}
