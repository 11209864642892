.main-content {
  .fas,
  .far {
    font-size: 1.5rem;
    color: $blue;
    padding: 0 .5rem;
  }
  
  .fa-spin {
    animation-duration: 1.5s;
  }
}