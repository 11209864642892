/* Bootstrap libraries required for variables and mixins used in below sheets */

.hidden {
  display: none !important;
}

.invisible {
  opacity: 0 !important;
}

.invisible-children > * {
  opacity: 0 !important;
}

.collapsed {
  flex: 0 0 0 !important;
  transition: flex 400ms ease-in-out;
}

.no-overflow {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.w-100 {
  width: 100%;
}

.p-1 {
  padding: 1rem;
}

.px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.p-2 {
  padding: 2rem;
}

.px-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pr-1 {
  padding-right: 1rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pl-2 {
  padding-left: 2rem;
}

.pr-2 {
  padding-right: 2rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pr-4 {
  padding-right: 4rem;
}

.smaller-font {
  font-size: 1.5rem;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.error {
  background-color: #f0c8d0;
  color: #BA324F;
}

.text-center {
  text-align: center;
}

.abs-fill-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.fill {
  position: relative;
  height: 100%;
  width: 100%;
}

.flex-auto {
  flex: 0 0 auto;
}

.flex-none {
  flex: 0;
}

.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-item {
  position: relative;
  flex: 1;
  transition: flex 400ms ease-in-out;
}

.flex-item-2 {
  position: relative;
  flex: 2;
  transition: flex 400ms ease-in-out;
}

.flex-item-3 {
  position: relative;
  flex: 3;
  transition: flex 400ms ease-in-out;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-align-center {
  align-items: center;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-wrap {
  flex-wrap: wrap;
}

.red,
.alert-danger {
  color: #BA324F;
}

.alert-info {
  color: #4A90E2;
  border-color: rgba(74, 144, 226, 0.5);
}

.color-neutral {
  color: #A4A4A4;
}

.color-info {
  color: #4A90E2;
}

.green,
.success {
  color: #83CE96;
}

.cursor-pointer {
  cursor: pointer;
}

.ing-dropdown {
  position: relative;
  min-width: 0;
  align-items: center;
  display: flex;
}

.ing-dropdown .dropdown-toggle {
  overflow: hidden;
  align-items: center;
  display: flex;
  font-weight: 800;
  flex: 1;
}

.ing-dropdown,
.ing-dropdown > .btn,
.ing-dropdown > .input-group-btn {
  min-height: 4rem;
}

.ing-dropdown .input-group-btn {
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex: 1;
}

.ing-dropdown .btn > .btn-text {
  flex: 1;
  text-align: left;
  font-weight: 600;
}

.ing-dropdown .input-group-addon {
  flex: 0 0 auto;
  width: initial;
  border-right: 1px solid rgba(74, 144, 226, 0.3);
}

.ing-dropdown .dropdown-menu {
  max-height: 40rem;
  min-width: 50%;
  overflow: auto;
}

.ing-dropdown .dropdown-menu > li > a > svg,
.ing-dropdown .dropdown-group-menu > li > a > svg {
  margin-left: .5rem;
}

.ing-dropdown .dropdown-menu > li > a,
.ing-dropdown .dropdown-group-menu > li > a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 2rem;
  font-size: 1.3rem;
}

.ing-dropdown .dropdown-menu > li.selected .selected-icon,
.ing-dropdown .dropdown-group-menu > li.selected .selected-icon {
  position: relative;
  right: 0;
  font-size: 2.3rem;
}

.ing-dropdown .dropdown-menu > li.disabled,
.ing-dropdown .dropdown-group-menu > li.disabled {
  cursor: not-allowed;
}

.ing-dropdown .dropdown-menu > li.disabled svg,
.ing-dropdown .dropdown-group-menu > li.disabled svg {
  opacity: .5;
}

.ing-dropdown .dropdown-group {
  display: initial;
}

.ing-dropdown .dropdown-group .dropdown-group-menu {
  display: block;
}

.ing-dropdown .dropdown-group-header {
  background-color: #4A90E2;
  padding: 1rem 2rem;
  margin: 0;
  color: white;
}

