.react-datepicker {
  display: flex;
  &-wrapper {
    .react-datepicker__input-container {
      input {
        &::placeholder {
          color: #333;
          font-size: 1.4rem;
        }
      }
    }
  }
  &__navigation {
    top: 1rem;
    &--previous {
      left: 3.5rem;
      position: relative;
      order: 2;
    }
  }
  &__header {
    font-size: 1.2rem;
    background: #fff;
  }
  // monthly picker styles
  &__month-text {
    padding: .5rem;
    font-size: 1.2rem;
    font-family: arial;
    min-width: 8rem;
    width: auto;
    &--today {
      &:hover {
        background-color: #f0f0f0;
      }
    }
    &:not(.react-datepicker__month--in-range) {
      &--keyboard-selected {
        background: transparent;
        color: #000;
        &:hover {
          background-color: #f0f0f0;
          color: #000;
        }
      }
    }
  }
  &__month-wrapper {
    display: flex;
    justify-content: space-evenly;
  }
  &__month-wrapper .react-datepicker {
    &__month--selected {
      &:not(.react-datepicker__month--in-range) {
        background: transparent;
        color: #000;
        &:hover {
          background: #f0f0f0;
        }
      }
    }
  }
  &__month--selected,
  &__month--in-range {
    background: #357ebd !important;
    color: #fff !important;
  }
  &__month-container {
    float: unset;
    width: 100%;
    order: 3;
  }
  .react-datepicker__month-text--keyboard-selected {
    &.react-datepicker__month--in-range {
      background: #357ebd;
    }
    &:not(.react-datepicker__month--in-range) {
      background: transparent;
      color: #000;
    }
    &.react-datepicker__month--disabled {
      color: #ccc;
    }
    &:hover {
      background: #f0f0f0;
    }
  }
}

// day specific
.react-datepicker {
  &__day {
    min-width: 3rem;
    font-size: 1.2rem;
    font-family: arial;
    padding: .5rem;
    &--in-range {
      background: #357ebd;
    }
    &--outside-month {
      background-color: transparent !important;
    }
    &--keyboard-selected {
      &:not(.react-datepicker__day--in-range) {
        background: transparent;
        color: #000;
        &:hover {
          background: #f0f0f0;
        }
      }
    }
  }
  &__day-names {
    margin-bottom: 0;
    margin-top: 1rem;
  }
  &__day-name {
    min-width: 3rem;
    font-weight: 600;
  }
  &__current-month {
    font-family: arial;
    font-size: 1.2rem;
  }
  &__month-container {
    width: auto;
    margin: .5rem 1.5rem;
  }
}

// presets section
.react-datepicker {
  .presets {
    position: relative;
    order: 1;
    width: 12rem;
    border-right: 1px solid #aeaeae;
    right: -2.5rem;
    .preset {
      margin-left: -2.5rem;
      padding: .5rem 0;
      font-size: 1.1rem;
      font-family: arial;
      padding: .8rem 1.2rem;
      &:hover {
        background: #EEEEEE;
        cursor: pointer;
      }
      &.active {
        background: #08c;
        color: #fff;
      }
    }
  }
}

.react-datepicker__month--selected {
  background: transparent !important;
  color: black !important;
}
.react-datepicker__month--in-range {
  background: #357ebd !important;
  color: #fff !important;
}

.react-datepicker-popper.selection-mode {
  .react-datepicker__month--selected {
    background: #357ebd !important;
    color: #fff !important;
  }
}
