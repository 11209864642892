/* Bootstrap libraries required for variables and mixins used in below sheets */

.hidden {
  display: none !important;
}

.invisible {
  opacity: 0 !important;
}

.invisible-children > * {
  opacity: 0 !important;
}

.collapsed {
  flex: 0 0 0 !important;
  transition: flex 400ms ease-in-out;
}

.no-overflow {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.w-100 {
  width: 100%;
}

.p-1 {
  padding: 1rem;
}

.px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.p-2 {
  padding: 2rem;
}

.px-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pr-1 {
  padding-right: 1rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pl-2 {
  padding-left: 2rem;
}

.pr-2 {
  padding-right: 2rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pr-4 {
  padding-right: 4rem;
}

.smaller-font {
  font-size: 1.5rem;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.error {
  background-color: #f0c8d0;
  color: #BA324F;
}

.text-center {
  text-align: center;
}

.abs-fill-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.fill {
  position: relative;
  height: 100%;
  width: 100%;
}

.flex-auto {
  flex: 0 0 auto;
}

.flex-none {
  flex: 0;
}

.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-item {
  position: relative;
  flex: 1;
  transition: flex 400ms ease-in-out;
}

.flex-item-2 {
  position: relative;
  flex: 2;
  transition: flex 400ms ease-in-out;
}

.flex-item-3 {
  position: relative;
  flex: 3;
  transition: flex 400ms ease-in-out;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-align-center {
  align-items: center;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-wrap {
  flex-wrap: wrap;
}

.red,
.alert-danger {
  color: #BA324F;
}

.alert-info {
  color: #4A90E2;
  border-color: rgba(74, 144, 226, 0.5);
}

.color-neutral {
  color: #A4A4A4;
}

.color-info {
  color: #4A90E2;
}

.green,
.success {
  color: #83CE96;
}

.cursor-pointer {
  cursor: pointer;
}

.labeled-lookup-option.react-select__from-history {
  color: #4A90E2;
}

.labeled-lookup-option.react-select__from-history.react-select__option--is-selected {
  color: white;
}

.labeled-lookup-option.react-select__from-history .context {
  color: #8DB7EE;
}

.labeled-lookup-option .primary {
  font-weight: bold;
  padding-right: .5rem;
  font-size: 1.2rem;
}

.labeled-lookup-option .implicit {
  padding-right: 0.5rem;
}

.labeled-lookup-option .context {
  color: #A4A4A4;
  font-weight: normal;
  font-size: 1.2rem;
}

.labeled-lookup-option .context .context-label {
  text-transform: uppercase;
  font-size: 1rem;
  padding-right: .5rem;
}

.labeled-lookup-option .context .context-label.has-value:after {
  content: ":";
  padding-right: 0.2rem;
}

.labeled-lookup-option .context:not(:last-child):after {
  content: "/";
  padding: 0 .5rem;
  font-weight: normal;
}

.labeled-lookup-option .primary:after,
.labeled-lookup-option .context:after {
  content: " ";
}

@media screen and (max-width: 1000px) {
  .ing-labeled-select {
    flex: 1 1 100%;
  }
}

@media screen and (min-width: 1401px) {
  .ing-labeled-select {
    flex: 1 1 32%;
    margin-right: 1rem;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1400px) {
  .ing-labeled-select {
    flex: 1 1 48%;
    margin-right: 1rem;
  }

  .lookup-filters {
    justify-content: space-between;
  }
}

.lookup-filters .ing-labeled-select {
  max-width: 100%;
}

.react-select-container {
  height: 100%;
  flex: 1;
  max-width: 100%;
}

.react-select-container .react-select__control {
  flex-wrap: nowrap;
  height: 100%;
  box-shadow: none;
  max-width: 100%;
}

.react-select-container .labeled-lookup-option,
.react-select-container .react-select__multi-value {
  font-weight: 800;
  background: transparent;
  min-width: auto;
}

.react-select-container .labeled-lookup-option__label,
.react-select-container .react-select__multi-value__label {
  font-size: unset;
}

.react-select-container .labeled-lookup-option__remove:hover,
.react-select-container .react-select__multi-value__remove:hover {
  background: transparent;
  color: #000;
}

.react-select-container .react-select__clear-indicator {
  transition: color 150ms;
  padding: 0 1.2rem;
  color: gray;
  border-left: 1px solid rgba(74, 144, 226, 0.3);
}

/* 
  This styles the pills of the react-select option
  when isMulti is set
  Enable if/when allowing user to select multiple options
*/

