/* Bootstrap libraries required for variables and mixins used in below sheets */

.hidden {
  display: none !important;
}

.invisible {
  opacity: 0 !important;
}

.invisible-children > * {
  opacity: 0 !important;
}

.collapsed {
  flex: 0 0 0 !important;
  transition: flex 400ms ease-in-out;
}

.no-overflow {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.w-100 {
  width: 100%;
}

.p-1 {
  padding: 1rem;
}

.px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.p-2 {
  padding: 2rem;
}

.px-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pr-1 {
  padding-right: 1rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pl-2 {
  padding-left: 2rem;
}

.pr-2 {
  padding-right: 2rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pr-4 {
  padding-right: 4rem;
}

.smaller-font {
  font-size: 1.5rem;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.error {
  background-color: #f0c8d0;
  color: #BA324F;
}

.text-center {
  text-align: center;
}

.abs-fill-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.fill {
  position: relative;
  height: 100%;
  width: 100%;
}

.flex-auto {
  flex: 0 0 auto;
}

.flex-none {
  flex: 0;
}

.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-item {
  position: relative;
  flex: 1;
  transition: flex 400ms ease-in-out;
}

.flex-item-2 {
  position: relative;
  flex: 2;
  transition: flex 400ms ease-in-out;
}

.flex-item-3 {
  position: relative;
  flex: 3;
  transition: flex 400ms ease-in-out;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-align-center {
  align-items: center;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-wrap {
  flex-wrap: wrap;
}

.red,
.alert-danger {
  color: #BA324F;
}

.alert-info {
  color: #4A90E2;
  border-color: rgba(74, 144, 226, 0.5);
}

.color-neutral {
  color: #A4A4A4;
}

.color-info {
  color: #4A90E2;
}

.green,
.success {
  color: #83CE96;
}

.cursor-pointer {
  cursor: pointer;
}

/*
  TODO: All styles in this sheet need to move to
  /common (in respective components) as there are
  no filter-specific styles here.
 */

/*
  These filters should not flex-grow and only
  take up as much space as needed
*/

.ing-labeled-static,
.ing-labeled-dropdown {
  flex: 0 1 auto;
}

.lookup-filters:hover .ing-labeled-select .pending-clear .react-select__control,
.lookup-filters:hover .ing-labeled-select .pending-clear .react-select__control--is-focused {
  border-color: #BA324F;
  box-shadow: 0 0 0 1px #BA324F;
}

.lookup-filters:hover .ing-labeled-select .pending-clear .react-select__clear-indicator {
  color: #4d4d4d !important;
}

.lookup-filters:hover .ing-labeled-select .pending-clear .react-select__control--is-focused
.react-select__clear-indicator {
  color: black !important;
}

.ing-labeled-static,
.ing-labeled-dropdown,
.ing-labeled-select .react-select__control {
  border-color: transparent;
  background: linear-gradient(130deg, #f2e8fb 0%, #f3e4ff 10%, #e0e7fd 80%, #dee7ff 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.ing-labeled-static,
.ing-labeled-dropdown {
  max-width: 100%;
  margin-right: 1rem;
}

.ing-labeled-static,
.ing-labeled-dropdown,
.ing-labeled-select {
  display: flex;
  align-items: center;
  min-height: 4.8rem;
  margin-bottom: 1rem;
  border-radius: 4px;
}

.ing-labeled-static .btn-default,
.ing-labeled-static .open > .btn-default,
.ing-labeled-static .open > .btn-default.dropdown-toggle:hover,
.ing-labeled-static .input-group-addon,
.ing-labeled-dropdown .btn-default,
.ing-labeled-dropdown .open > .btn-default,
.ing-labeled-dropdown .open > .btn-default.dropdown-toggle:hover,
.ing-labeled-dropdown .input-group-addon,
.ing-labeled-select .btn-default,
.ing-labeled-select .open > .btn-default,
.ing-labeled-select .open > .btn-default.dropdown-toggle:hover,
.ing-labeled-select .input-group-addon {
  background: transparent;
  border: none;
}

.ing-labeled-static .input-group-btn > .btn,
.ing-labeled-static .input-group-static,
.ing-labeled-dropdown .input-group-btn > .btn,
.ing-labeled-dropdown .input-group-static,
.ing-labeled-select .input-group-btn > .btn,
.ing-labeled-select .input-group-static {
  font-weight: 800;
}

.ing-labeled-static .input-group-static,
.ing-labeled-dropdown .input-group-static,
.ing-labeled-select .input-group-static {
  padding: 1rem;
}

.ing-labeled-static .input-group-btn,
.ing-labeled-static .input-group-static,
.ing-labeled-dropdown .input-group-btn,
.ing-labeled-dropdown .input-group-static,
.ing-labeled-select .input-group-btn,
.ing-labeled-select .input-group-static {
  display: inline-flex;
  flex: 1;
}

.ing-labeled-static .input-group-addon,
.ing-labeled-dropdown .input-group-addon,
.ing-labeled-select .input-group-addon {
  flex: 0 0 auto;
  width: initial;
}

.ing-labeled-static .input-group-addon,
.ing-labeled-dropdown .input-group-addon,
.ing-labeled-select .input-group-addon {
  border-right: 1px solid rgba(74, 144, 226, 0.3);
}

