/* Bootstrap libraries required for variables and mixins used in below sheets */

.hidden {
  display: none !important;
}

.invisible {
  opacity: 0 !important;
}

.invisible-children > * {
  opacity: 0 !important;
}

.collapsed {
  flex: 0 0 0 !important;
  transition: flex 400ms ease-in-out;
}

.no-overflow {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.w-100 {
  width: 100%;
}

.p-1 {
  padding: 1rem;
}

.px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.p-2 {
  padding: 2rem;
}

.px-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pr-1 {
  padding-right: 1rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pl-2 {
  padding-left: 2rem;
}

.pr-2 {
  padding-right: 2rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pr-4 {
  padding-right: 4rem;
}

.smaller-font {
  font-size: 1.5rem;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.error {
  background-color: #f0c8d0;
  color: #BA324F;
}

.text-center {
  text-align: center;
}

.abs-fill-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.fill {
  position: relative;
  height: 100%;
  width: 100%;
}

.flex-auto {
  flex: 0 0 auto;
}

.flex-none {
  flex: 0;
}

.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-item {
  position: relative;
  flex: 1;
  transition: flex 400ms ease-in-out;
}

.flex-item-2 {
  position: relative;
  flex: 2;
  transition: flex 400ms ease-in-out;
}

.flex-item-3 {
  position: relative;
  flex: 3;
  transition: flex 400ms ease-in-out;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-align-center {
  align-items: center;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-wrap {
  flex-wrap: wrap;
}

.red,
.alert-danger {
  color: #BA324F;
}

.alert-info {
  color: #4A90E2;
  border-color: rgba(74, 144, 226, 0.5);
}

.color-neutral {
  color: #A4A4A4;
}

.color-info {
  color: #4A90E2;
}

.green,
.success {
  color: #83CE96;
}

.cursor-pointer {
  cursor: pointer;
}

/* Structure */

.main-content {
  /* Buttons */
  /* Headings */
  /* Forms */
  /* Dropdown */
}

.main-content .btn.btn-danger,
.main-content .btn.btn-info,
.main-content .btn.btn-primary,
.main-content .btn.btn-success,
.main-content .btn.btn-warning {
  border: none;
  text-transform: uppercase;
  font-weight: 800;
  min-width: 15rem;
}

.main-content .btn,
.main-content .btn:disabled,
.main-content .btn:hover,
.main-content .btn:hover:disabled,
.main-content .btn:active,
.main-content .btn:active:disabled,
.main-content .form-control,
.main-content .form-control:disabled {
  border-radius: 4px;
}

.main-content .btn {
  height: 4rem;
  font-size: 1.4rem;
  font-weight: normal;
  padding: 0 2rem;
  line-height: 4rem;
  text-transform: initial;
}

.main-content .btn .btn-text {
  padding: 0 .5rem;
}

.main-content .btn .fas {
  color: white;
  padding: 0 .5rem;
}

.main-content .btn-default:hover {
  background-color: inherit;
  border-color: #ccc;
}

.main-content .btn-default:active,
.main-content .btn-default:focus,
.main-content .btn-default:active:focus,
.open > .main-content .btn-default.dropdown-toggle,
.open > .main-content .btn-default.dropdown-toggle:focus,
.open > .main-content .btn-default.dropdown-toggle:hover {
  background-color: transparent;
  box-shadow: initial;
  border-color: #4A90E2;
}

.main-content .btn-default:active:hover {
  background-color: transparent;
}

.main-content .btn-default,
.main-content .open > .btn-default,
.main-content .input-group-addon,
.main-content .form-control {
  background: white;
  border-color: #ccc;
}

.main-content .form-control {
  padding: 0 2rem;
  font-weight: 600;
}

.main-content .form-control:focus,
.main-content .form-control:active:focus {
  background-color: #f2f2f2;
}

.main-content .form-control:active,
.main-content .form-control:focus {
  border-color: #4A90E2;
}

.main-content .btn.btn-primary {
  background: #9e80e5;
  background: linear-gradient(135deg, #9e80e5 0%, #8488EF 50%, #6866e0 100%);
}

.main-content h1,
.main-content .h1 {
  font-size: 2rem;
  line-height: 2rem;
}

.main-content h2,
.main-content .h2,
.main-content legend,
.main-content .legend {
  font-size: 1.1rem;
  font-weight: 800;
  line-height: 1rem;
  color: #8DB7EE;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.main-content label,
.main-content .input-label {
  text-align: initial;
}

.main-content .radio:first-of-type {
  margin-top: 0;
}

.main-content legend,
.main-content .legend {
  border: initial;
}

.main-content .input-text {
  font-weight: 800;
  font-size: 1.1rem;
  text-transform: uppercase;
}

.main-content .dropdown-menu > li.selected,
.main-content .dropdown-group-menu > li.selected {
  background-color: #F4F8FE;
}

.main-content .dropdown-menu > li > a,
.main-content .dropdown-group-menu > li > a {
  color: #4B5055;
}

.main-content .dropdown-menu > li > a:hover,
.main-content .dropdown-group-menu > li > a:hover {
  text-decoration: none;
  background-color: #dde9fc;
  color: inherit;
}

.main-content .alert {
  border: 1px solid transparent;
  border-radius: 4px;
  font-weight: normal;
}

.main-content .alert .close {
  color: #4B5055;
}

.main-content .alert-danger {
  color: #BA324F;
  border-color: #ebccd1;
  background-color: #f2dede;
  border-width: 1px;
}

.main-content .alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.main-content .radio label {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.main-content .radio label input[type=radio] {
  margin-top: initial;
}

