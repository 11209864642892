@mixin gradient($color) {
  background: linear-gradient(
    -15deg, 
    saturate(darken($color, 10%), 10%), 
    desaturate(lighten($color, 10%), 10%)
  )
}

@mixin commonGradientBright {
  background: #9e80e5;
  background: linear-gradient(
    135deg,
    #9e80e5 0%,
    #8488EF 50%,
    #6866e0 100%
  );
}

@mixin commonGradientLight {
  background: #DED9F5;
  background: linear-gradient(
    to left top,
    #DED9F5 0%,
    #CBE1FE 45%,
    #CBE1FE 55%,
    #C6CFF2 100%
  );
}

@mixin serif-font {
  font-family: $serifFont;
}

@mixin sans-font {
  font-family: $sansFont;
}

@mixin base-font {
  font-family: $baseFont;
}

@mixin border-radius {
  border-radius: $borderRadius;
}

@mixin padder {
  padding: $spacer;
  box-sizing: border-box;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-item {
  flex: 1;
  transition: flex 400ms ease-in-out;
}

@mixin flex-item-unit($flex-unit) {
  flex: $flex-unit;
  transition: flex 400ms ease-in-out;
}

@mixin flex-auto {
  flex: 0 0 auto;
}

@mixin flex-center {
 justify-content: center;
 align-items: center;
}

@mixin fill {
  position: relative;
  height: 100%;
  width: 100%;
}

@mixin abs-fill {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin transition($duration, $delay, $prop) {
  transition: $prop #{$duration}ms ease-in-out #{$delay}ms;
}

@mixin flex-transition($duration, $easing: ease-in-out) {
  transition: flex #{$duration}ms $easing;
}

@mixin ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin word-break {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

@mixin gradient-fill {
  background-image: linear-gradient(to bottom right, #DED9F5 0%,#CBE1FE 50%,#C6CFF2 100%);
}

@mixin remove-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
      display: none;
  }
}
